.Loadercss {
    overflow-x: hidden;
    overflow-y: hidden;
}

.Loadercss .bar {
  position: relative;
  height: 2px;
  width: 500px;
  margin: 0 auto;
  background: #ffcc00;
  margin-top: 50px;
  
}

.Loadercss .circle {
  position: absolute;
  top: -30px;
  margin-left: -30px;
  height: 60px;
  width: 60px;
  left: 0;
  background: #ffcc00;
  border-radius: 40%;
  -webkit-animation: move 5s infinite;
}

.Loadercss p {
  position: absolute;
  top: -24px;
  right: -72px;  
  color:white;
  font-family: helvetica, sans-serif;
  font-weight: bold;
}

@-webkit-keyframes move {
  0% {left: 0;}
  50% {left: 100%; -webkit-transform: rotate(450deg); width: 150px; height: 150px;}
  75% {left: 100%; -webkit-transform: rotate(450deg); width: 150px; height: 150px;}
  100% {right: 100%;}
} 
.label-send-wrapper {
    width: 10cm;
    height: 15cm;
    border: 1px #d3d3d3 solid;
    padding: 10px;
    font-size: 11px;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    .header-brand {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px solid #c6c6c6;
        padding-bottom: 10px;

        h3 {
            font-size: 18px;
            font-weight: 700;
            font-family: sans-serif;
            margin: 0;
        }
    }

    .shipment-wrapper {
        display: flex;
        flex-direction: row;
        font-family: sans-serif;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px solid #c6c6c6;
        padding-bottom: 10px;

        .shipment-service-detail {
            text-align: center;
            width: 100%;

            table {
                width: 100%;

                td {
                    padding: 5px;

                    .footer-background-logo {
                        background-image: url("https://cdngarenanow-a.akamaihd.net/shopee/shopee-pcmall-live-id/assets/ed4e16c501919a270e9a38deb6603653.png");
                    }

                    .footer-jne-png {
                        width: 55px;
                        height: 17px;
                        background-position: 58.38247683235046% 5.882352941176471%;
                        margin: auto;
                    }
                }
            }
        }

        .barcode-shipment {
            text-align: center;
            line-height: 1;
            font-size: 10px;
        }
    }

    .line-sparator {
        width: 100%;
        border-bottom: 1px solid #c6c6c6;
    }

    .address-wrapper {
        margin-top: 10px;
        padding-bottom: 10px;
        // border-bottom: 1px #c6c6c6 solid;

        p {
            margin: 0;
        }
    }

    .product-wrapper {
        table {
            td {
                padding: 0px;
            }
        }
    }
}

.button-print {
    padding: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    background: #ffffff;
    width: 120px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img{
        margin-right: 10px;
    }
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    .label-send-wrapper {
        page-break-after: always;
        margin-bottom: 0px;
    }
}

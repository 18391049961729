.popup-wrapper {
  // position: fixed;
  // height: 100vh;
  // width: 100vw;
  top: 0;
  left: 0;
  z-index: 1106;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-card {
    min-width: 400px;
    max-width: 50vw;
    min-height: 130px;
    background: white;
    padding: 20px;
    z-index: 100;
    border-radius: 5px;
    max-height: 100vh;
    overflow: scroll;

    .popup-header {
      border-bottom: 1px solid rgb(207, 207, 207);
      padding: 10px 0px;
      display: flex;
      align-content: center;
      justify-content: center;
      font-weight: 700;
      font-size: 15pt;
      color: grey;
      position: relative;

      i {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }

    .popup-body {
      padding: 20px 0px;
      display: flex;
      overflow: hidden;

      .displayProduct {
        width: 20%;
        background: gray;
        display: block;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }

      .ProductTitle {
        padding: 0px 15px;
        color: rgb(155, 155, 155);
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

.branch-verficiation-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  margin-left: -210px;
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.7;
  z-index: 99;
  left: 0;
  top: 0;
}

.body-branch-selector {
  width: 100%;
  text-align: center;
  padding: 10px 40px;
  display: flex;
  flex-direction: column;

  .body-box-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .branch-card-box {
      display: flex;
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
      width: 130px;
      height: 130px;
      margin: 20px;
      flex-direction: column;
      justify-content: center;
      border: 1px solid whitesmoke;
      border-radius: 10px;
      padding: 10px;

      i {
        font-size: 20pt;
        padding: 10px;
      }

      span {
        padding: 10px;
        font-weight: 700;
      }

      &:hover {
        background: #ffcc00;
        cursor: pointer;
      }
    }

    .branch-selected {
      background: #ffcc00;
    }
  }
}

@media screen and (max-width: 460px) {
  .popup-wrapper {
    .popup-card {
      min-width: 100vw;
      max-width: 100vw;
    }
  }
}

.hide {
  display: none !important;
}

.popup_displayproduct {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 980px) {
  .popup-wrapper {
    .popup-card {
      min-width: 70vw;
      width: 90%;
      max-width: 100vw;
    }
  }

  .branch-verficiation-wrapper {
    margin-left: 0px;
  }
}

.progress-bar-class {
    position: fixed;
    width: 80%;
    z-index: 1;
    background: white;
    padding: 10px;
    bottom: 1px;
}

@media screen and (max-width: $break-small) {
    .progress-bar-class {
        position: fixed;
        width: 92%;
        z-index: 1;
    }
  }
  
  @media screen and (max-width: $break-medium) {
    .progress-bar-class {
        position: fixed;
        width: 96%;
        z-index: 1;
    }
  }
  
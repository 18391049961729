.otd-dard-header-short {
  width: 100%;
  background: white;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  margin-top: 15px;
  padding: 10px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;

  .col1 {
    width: 10%;
  }

  .col2 {
    width: 30%;
  }

  .col3 {
    width: 20%;
  }

  .col4 {
    width: 20%;
  }

  .col5{
    width: 20%;
}

  &:hover {
    background: #f4d64f;
    cursor: pointer;
  }
}
.yellow {
  background: #f4d64f;
}

.otd-open {
  height: auto !important;
  padding: 25px;
}

.otd-card {
  width: 100%;
  height: 0px;
  overflow: scroll;
  transition: 0.2s;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  line-height: 24px;
  justify-content: space-between;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  // align-items:
  font-size: 10pt;

  .otd-card-description {
    width: 40%;
    position: relative;

    .otd-opened {
      max-height: 300px !important;
      padding: 15px;
    }

    .backdrop {
      width: 100vw;
      height: 100vh;
      background: black;
      opacity: 0.7;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1045;
    }

    .otd-dtail-order-wrapper {
      position: absolute;
      background: rgb(255, 255, 255);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 1200;
      max-height: 0px;
      overflow: scroll;
      transition: 0.2s;

      .otd-dtail-inner {
        position: relative;

        .close-button {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 14pt;
          color: white;
          cursor: pointer;
        }

        .otd-header-product {
          font-weight: 700;
          text-align: left;
          font-size: 13pt;
          background: grey;
          color: white;
          padding: 11px;
          // position: absolute;
        }

        .otd-product {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid rgb(199, 199, 199);
          padding: 10px 0px;

          .pict {
            img {
              height: 80px;
              padding-right: 10px;
            }
          }

          .desc {
            flex: 1;
            text-transform: capitalize;
            line-height: 1.5;
            padding-right: 10px;
            border-right: 1px solid whitesmoke;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }

          .totalPrice {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    h5 {
      margin: 0;
    }

    .shipment-info-wrapper {
      background: #ebedeb;
      padding: 10px;
      margin-top: 20px;
      margin-right: 20px;
    }
  }

  .otd-timeline-process {
    line-height: 1;
    width: 30%;
    background: #ebedeb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    // input resi
    .inpr-wrapper-open {
      height: 100% !important;
      padding: 10px;
    }

    .inpr-wrapper {
      width: 100%;
      position: absolute;
      display: flex;
      height: 0px;
      justify-content: center;
      align-items: flex-start;
      background: #ebedeb;
      overflow: hidden;
      // height: 0%;
      z-index: 1200;
      flex-direction: column;
      // padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: 0.2s;

      .descri {
        margin-bottom: 10px;
      }
    }

    .chip {
      font-size: 8pt;
      padding: 5px;
      // bo
      margin-top: 2px;
      line-height: 13px;
      color: white;
      background: rgb(160, 160, 160);
      display: flex;
      flex-direction: row;
      align-items: center;

      strong {
        width: 50%;
        font-size: 8pt;
      }
    }

    .yellow {
      background: #ea9100;
    }

    .green {
      background: #20c160;
    }

    .blue {
      background: #1575d4;
    }

    .red {
      background: #f0544b;
    }

    .blue-light {
      background: #3acced;
    }
  }

  .otd-card-shipment {
    width: 30%;
    background: #ebedeb;
    padding: 20px;

    h4 {
      margin: 0;
    }
  }
}

@media screen and (max-width: $break-medium) {

  .otd-dard-header-short {
    flex-direction: column;
    position: relative;
  
    .col1 {
      width: 100%;
    }
  
    .col2 {
      width: 100%;
    }
  
    .col3 {
      width: 100%;
    }
  
    .col4 {
      width: 100px;
      position: absolute;
      right: 10px;
    }
  
    .col5{
      width: 100%;
      margin-top: 10px;
  }
  
  }

  .otd-card {
    .otd-card-description {
      .otd-opened {
      }
    }
  }
}

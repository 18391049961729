.required-label {
    border: 1px solid #e0e0e0;
    color: rgba(0, 0, 0, 0.7);
    font-size: 9px;
    margin: 10px;
    padding: 1px 9px;
    border-radius: 6px;
}

.label-wrapper{
    margin:9px 0px;
}
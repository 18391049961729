.field-upload-image{    
    border: 2px dashed #bbb !important;
    display: block;
    text-align: center;
    padding: 20px;
}


.text-desc {
    margin: 15px;
}

.sticky{
    position:fixed;
    top: 0px;
}

.baseStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    width: 100%;
    height: 250px;
    border-width: 2;
    border-color: #bbb;
    border-style: dashed;
    padding: 20px;
    cursor: pointer
  };


.activeStyle {
    border-style: solid;
    border-color: #6c6;
    background-color: #eee
  };
.rejectStyle {
    border-style: solid;
    border-color: #c66;
    background-color: #eee
  };

.preview-thumbnail{
    padding: 10px;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
  }

  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 14pt;
}